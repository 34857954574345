import React, {Component} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import ArrowNavigationIcon from "@material-ui/icons/NearMe"
import WaveSvg from "../assets/images/Wave.svg"
import {isValidContentfulImage, isValidPhone, isValidSectionField} from "../common/utils/CommonUtils"
import {graphql} from "gatsby"
import {SegmentEvents} from "../common/utils/SegmentSpec";
import {SegmentAnalytics} from "../common/utils/SegmentAnalytics";
import BreadCrumbs from "../components/bread-crumbs";
import {BranchUtils} from "../common/utils/BranchUtils";
import "../pages/courses-get-app.scss";

const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    },
    {
        title: "Get Started",
        link: "/get-started/",
    },
    {
        title: "How to Guides",
        link: "/how-to-guides/",
    },
    {
        title: "All Courses",
        link: "/how-to-guides/mental-health-and-substance-use/",
    },


]

export default class CourseGetApp extends Component {
    constructor(props) {
        super(props);
        this.pageData = props.data.contentfulCourseDetailPage;
        this.state = {
            phone: "",
            success: false,
            error: false,
        }

    }

    sendPhone = (event, phoneNumber) => {
        event.preventDefault();
        const phone = isValidPhone(phoneNumber);
        if (phone) {

            let currentPage = typeof window !== 'undefined'
                ? window.location.pathname : '';
            currentPage = currentPage.split('/')[2];
            if (currentPage === "") {
                currentPage = "home-page"
            }
            SegmentAnalytics.track(
                SegmentEvents.USER_REQUEST_FOR_COURSE_GET_THE_APP, {
                    phone,
                    'formLocation': currentPage,
                    appRequested: true,
                });

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'formLocation': currentPage,
                'event': 'GET_THE_APP',
                'phone': phone
            });

            BranchUtils.desktopSendSMS(phone, currentPage,'Course Sign Up', this.pageData.topSection.title);

            this.setState({success: true, error: false});

        } else {
            this.setState({success: false, error: true, phone: ""});
        }
    };

    render() {
        const {topSection, benefits, courseOverview, courseBullets} = this.pageData;
        return (
            <Layout>
                <SEO title="Self-Directed Course Overview"/>
                <BreadCrumbs currentPage={this.pageData.topSection.title} breadCrumbsList={breadCrumbsList}/>
                <div className="common-detailed-header">
                    <div className="common-detailed-inner">
                        <div className="common-detailed-pattern-content">
                            {
                                isValidSectionField(topSection, "title") &&
                                <h2 className="common-detailed-main-head">You’re
                                    Enrolled For {topSection.title}</h2>
                            }
                            <p className="common-download-app-text">
                                Download the app to begin
                            </p>
                            <form method="post" onSubmit={(event) => {
                                this.sendPhone(event, this.state.phone)
                            }}>
                                <div className="get-app-input-wrap">
                                    <div className="input-box">
                                        <TextField
                                            id="phone-input"
                                            className="number-field"
                                            placeholder="Enter your Phone"
                                            value={this.state.phone}
                                            margin="normal"
                                            onChange={event => {
                                                const {value} = event.target;
                                                this.setState({
                                                    success: false,
                                                    error: false,
                                                    phone: value
                                                });
                                            }}
                                        />
                                    </div>
                                    <Button
                                        className="blue-arrow-btn"
                                        color="primary"
                                        type="submit"
                                    >
                                        Get the App <ArrowNavigationIcon/>
                                    </Button>
                                </div>
                            </form>
                            {this.state.error ? <p
                                className="course-get-app-error-msg">Please
                                Enter Valid Phone</p> : null}
                            {this.state.success ? <p
                                className="course-get-app-success-msg">Got it! Sending you a text with the link</p> : null}
                        </div>
                        <div className="common-detailed-video-side">
                            <div className="common-detailed-video-inner">
                                <div className="video-wrapper">
                                    {topSection && topSection.imageOrVideo
                                    !== null
                                    && topSection?.imageOrVideo?.file?.contentType.includes(
                                        "image") ?
                                        <img src={"https:"
                                        + topSection.imageOrVideo?.file?.url}
                                             alt="CourseImage"/>
                                        :
                                        <img src={require(
                                            "../assets/images/courses-video.png")}
                                             alt="CourseImage"/>
                                    }
                                </div>
                                <div className="course-info-wrapper">
                                    {
                                        topSection && topSection.courseMeta
                                        && topSection.courseMeta.map(meta => (
                                            <div className="single-course-info">
                                                {
                                                    isValidContentfulImage(
                                                        meta.image) && (
                                                        <img
                                                            className="info-icon"
                                                            src={meta.image.file.url}
                                                            alt="Icon"/>
                                                    )
                                                }
                                                <div className="info-content">
                                                    {
                                                        isValidSectionField(
                                                            meta, "name") &&
                                                        <p className="title">{meta.name}</p>
                                                    }
                                                    {
                                                        isValidSectionField(
                                                            meta, "description")
                                                        &&
                                                        <p className="des">{meta.description}{meta.name
                                                        === 'CHAPTERS'
                                                            ? "Chapters"
                                                            : ""}</p>
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="common-detailed-wave-wrapper"
                     style={{backgroundImage: `url(${WaveSvg})`}}>
                    <div className="wave-bg">
                        <div className="common-detailed-feature-inner">
                            {
                                benefits && benefits.map(benefit => (
                                    <div
                                        className="single-common-detailed-feature">
                                        {
                                            isValidContentfulImage(
                                                benefit.image) && (
                                                <img
                                                    className="single-common-detailed-img"
                                                    src={benefit.image.file.url}
                                                    alt="Icon"/>
                                            )
                                        }
                                        <div
                                            className="common-detailed-feature-content">
                                            {
                                                isValidSectionField(benefit,
                                                    "name") &&
                                                <h2 className="head">{benefit.name}</h2>
                                            }
                                            {
                                                isValidSectionField(benefit,
                                                    "description") &&
                                                <p className="para">{benefit.description}</p>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className="course-descr-wrapper">
                    <div className="course-descr-inner">
                        <div className="course-descr-left-side">
                            {
                                isValidSectionField(courseOverview, "title") &&
                                <h2 className="course-descr-head">{courseOverview.title}</h2>
                            }
                            {
                                isValidSectionField(courseOverview,
                                    "description") &&
                                <p className="course-descr-para"
                                   dangerouslySetInnerHTML={{__html: courseOverview.description.description}}></p>
                            }
                        </div>
                        <div className="course-descr-right-side">
                            {
                                isValidSectionField(courseBullets, "title") &&
                                <h2 className="course-descr-head">{courseBullets.title}</h2>
                            }
                            <ul>
                                {
                                    courseBullets && courseBullets.bulletPoints
                                    && courseBullets.bulletPoints.map(
                                        bullet => (
                                            <li>
                                                <img
                                                    src={require(
                                                        "../assets/images/checkmark-circle-outlined.svg")}
                                                    alt="icon"/>

                                                <p>{bullet}</p>

                                            </li>
                                        ))
                                }
                            </ul>
                        </div>
                    </div>

                </div>
            </Layout>
        )

    }
}

export const query = graphql` 
query contentfulPublicCoursesGetAppBySlug($slug:String) {
 contentfulCourseDetailPage(slug: {eq: $slug}) {
    id
    slug
    
    topSection {
      title
      ... on ContentfulPageTopSection {
      description {
        description
      }
      }
      courseMeta {
        name
        ... on ContentfulSectionMeta{
        description
        image {
          file {
            contentType
            url
          }
        }
        }
      }
      imageOrVideo {
        file {
          contentType
          url
        }
      }
    }
    benefits {
      name
      ... on ContentfulSectionMeta{
      description
      image {
        file {
          url
          contentType
        }
      }
      }
    }
    courseOverview {
      title
      ... on ContentfulBenefitOverview {
      description {
        description
      }
      }
    }
    courseBullets {
      bulletPoints
      title
    }
 }
}`;
